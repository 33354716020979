import React from "react";
import { Container,Row, Col } from "react-bootstrap";
import { getSpacingClass } from "../../common-data/common-data";
// import FormLeftContent
import GetinTouch from "../../forms/get-in-touch";
import ValuationEnquiry from "../../forms/valuation-enquiry-form";
import CareerForm from "../../forms/career";
import FormLeftContent from "./FormLeftContent";

import "./FormModule.scss";

const formComponents = {
  'GetInTouchForm': GetinTouch,
  'ValuationEnquiryForm': ValuationEnquiry,
  'CareerForm': CareerForm
};


const FormModule = ({ ModuleData, ...PageData }) => {
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  // Conditional assignment of values
  const Section_Title = ModuleData?.Section_Title ? ModuleData.Section_Title : (PageData?.FormData
  ?.Section_Title ? PageData?.FormData?.Section_Title : "");
  const Section_Intro = ModuleData.Section_Intro ? ModuleData.Section_Intro : (PageData?.FormData?.Section_Intro ? PageData?.FormData.Section_Intro : "");
  const FormList = ModuleData.FormList ? ModuleData.FormList : (PageData?.FormData?.FormList ? PageData?.FormData.FormList : "");
  const FormContactData = (ModuleData.FormContactData?.length > 0) ? ModuleData.FormContactData : ((PageData?.FormData?.FormContactData?.length > 0) ? PageData?.FormData?.FormContactData : []);
  const Show_Review = ModuleData?.Show_Reviews ? ModuleData.Show_Reviews : (PageData?.FormData
    ?.Show_Review ? PageData?.FormData?.Show_Reviews : "");
    const formBgClass = 
    (ModuleData?.ModuleBGColor === "Grey" || (!ModuleData?.ModuleBGColor && FormList === "ValuationEnquiryForm")) &&
    ModuleData?.ModuleBGColor !== "White"
        ? 'grey-bg' 
        : '';
  
  // Get the form component from the mapping
  const FormComponent = formComponents[FormList] || null;

  return (
    <div className={`form-module ${spacingClass} ${formBgClass}`} id={`form-${FormList.toLowerCase()}`}>
     <Container>
        <Row>
          <Col xs={12} lg={4} className="left-column">
              <FormLeftContent title={Section_Title} content={Section_Intro} Show_Review = {Show_Review} contactinfo={ModuleData?.Show_Contact ? FormContactData :[]} WidgetID={PageData?.ReviewsData?.Badge_Widget_Code} />
          </Col>
          <Col xs={12} lg={{ span: 7, offset: 1 }} className="right-column form-module-right">
            {FormComponent ? <FormComponent /> : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FormModule;