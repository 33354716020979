import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import InputField from './elements/input';
import SelectField from './elements/select';
import TextAreaField from './elements/textarea';
import CheckboxField from './elements/checkbox';
//import RadioField from './elements/radio-select';
import RadioField from './elements/radio'
import ButtonField from './elements/button';
import HtmlBox from './elements/html';
import ReCaptchaBox from './elements/recaptcha';
import { postFormData } from "./api/Api";
import $ from 'jquery';
import axios from "axios";
import * as qs from "query-string";
import { getUser } from "@starberry/myaccount-website-utils";

const ENQUIRY_OPTIONS = [
  { label: "Book a Valuation", value: "Book a Valuation" },
  { label: "Property Management", value: "Property Management" },
  { label: "Other", value: "Other" },
];

function MySimpleForm(props) {
  const userData = getUser();
  const [validated, setValidated] = useState(false);
  const [enqtype, setEnqtype] = useState(ENQUIRY_OPTIONS[0].value);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [radio, setRadio] = useState(ENQUIRY_OPTIONS[0].value);
  const [formvalues, setFormvalues] = useState("");
  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = [
    {
      element: "config",
      formname: "Get In Touch",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "get_in_touch_user",
      email_temp_admin: "get_in_touch_admin",
      email_server_func: "get_started",
      event_tracking: "get_in_touch",
      page_url: "/contact"
    },
    /*{
      grpmd: "12",
      label: "What does your enquiry relate to?",
      placeholder: "What does your enquiry relate to?",
      name: "enquirytype",
      id: "enquirytype",
      element: "radio",
      required: true,
      type: "select",
      class: "select-box",
      labelClass: "text-20 bold",
      values: ENQUIRY_OPTIONS,
    },*/

    // {
    //   grpmd: "12",
    //   label: "I'm looking to sell or let my property",
    //   placeholder: "I'm looking to sell or let my property",
    //   value: "I'm looking to sell or let my property",
    //   name: "enquirytype",
    //   type: "radio",
    //   element: "radio",
    //   checked: true,
    //   required: true,
    //   patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
    //   labelClass: "content_b-18"
    // },
    // {
    //   grpmd: "12",
    //   label: "I'm interested in my property(s) being managed",
    //   placeholder: "I’m interested in my property(s) being managed",
    //   name: "enquirytype",
    //   value: "I'm interested in my property(s) being managed",
    //   type: "radio",
    //   element: "radio",
    //   required: true,
    //   patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
    //   labelClass: "content_b-18",
    //   checked: false,

    // },
    // {
    //   grpmd: "12",
    //   label: "Something else",
    //   placeholder: "Something else",
    //   value: "Something else",
    //   name: "enquirytype",
    //   type: "radio",
    //   element: "radio",
    //   required: true,
    //   patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
    //   labelClass: "content_b-18",
    //   lastchild: "last-child",
    //   checked: false,

    // },

    {
      grpmd: "12",
      label: "Name",
      placeholder: "Name",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      labelClass: "text-18 bold",
      defaultValue: userData?.name || ``
    },
    {
      grpmd: "12",
      label: "Email",
      placeholder: "Email Address",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "text-18 bold",
      defaultValue: userData?.email || ``
    },
    {
      grpmd: "12",
      label: "Phone Number",
      placeholder: "Phone Number",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "text-18 bold",
      maxlength: "15",
      defaultValue: userData?.mobile || ``
    },      
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Share any useful information",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows:"1",
      labelClass: "text-18 bold"
    },  
    {
      grpmd: "12",
      name: "Start a Conversation",
      type: "submit",
      element: "button",
      value: "submit",
      class: "form-btn btn-transparent-black"
    },
    {
      text: 'By clicking the above button I agree to the Anthony Pepe <a href="/terms-and-condition" class="content-link text-small">Terms & Conditions</a>',
      element: "html",
      class: "frmbtmtext text-small"
    },
    {
      element: "captcha",
      class: "",
      captchaRef: recaptchaRef
    },
  ];

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const getRadio = event => {
    event.target.value = event.target.value.trimStart()  
    setRadio(event.target.value)
    setEnqtype(event.target.value)

  }

  useEffect(() => {
    $('#enquirytypetrue').attr('checked',true)

    if (token !== '') {

 const processFormData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(apiRes => {

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );
      });

      formvalues['g-recaptcha-response'] = token;

      const axiosOptions_netlify = {
        url: fields[0].page_url,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_netlify)
        .then(response => {
          console.log('data stored')
        })
        .catch(err =>
          console.log(err)
        );
      const url = typeof window !== 'undefined' ? window.location.href : ''  
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': radio
      });

      setShowerror(false);
      setThankyou(true);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFormData();
    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;
    setEnqtype($("input[name=enquirytype]:checked").val())
   if(enqtype) {
      $("input[name=enquirytype]").val(enqtype)
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    } else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      // Object.keys(formsdata).map(key => (
      //   json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      // ))
      // Loop through each form element
      Object.keys(formsdata).forEach(key => {
        const element = formsdata[key];
        if (element.name) { // Check if the element has a name
          // Check if the element is a radio button and if it's checked
          if (element.type === 'radio') {
            if (element.checked) {
              json[element.name] = element.value;
            }
          } else {
            json[element.name] = element.value;
          }
        }
      });
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();
      setToken("aaa");
      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setEnqtype(ENQUIRY_OPTIONS[0].value);
      setRadio(ENQUIRY_OPTIONS[0].value);
    }
  };

  return (
    <div className="form stbform">

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}

    
      <Form className="contact-form get-in-touch-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  label={field.label}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  maxlength={field.maxlength}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  label={field.label}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }            
            if ("radio" === field.element) {
              return (
                <RadioField
                  label={field.label}
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}                  
                  labelClass={field.labelClass}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getRadio}
                  lastchild={field.lastchild}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
        <div ref={myRef} />
      </Form>
    </div>
  );
}

const MySimpleFormPage = (props) => (
  <MySimpleForm />
);

export default MySimpleFormPage;